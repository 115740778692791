import React from 'react'
import News from '../Components/News'

const maklat = () => {
  return (
    <>
    
    <div className="news my-5">
    <News/>
    </div>
    </>
  )
}

export default maklat