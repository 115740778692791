const Apis = [

    {
        category: "ابتسامه",
        images: [
            "https://drammar-clinics.com/images/products/products-img-12.jpg",
            "https://drammar-clinics.com/images/products/products-img-7.jpg",
            "https://drammar-clinics.com/images/products/products-img-8.jpg"
        ],
        headerss: [
            " فينير (ابتسامة هوليود)",
            "  ما هو الفينير؟",
            "المشاكل التي يعالجها الفينير؟",
            "كيف يتم تركيب الفينير؟ ",
            "من هم المرشحون لإجراء فينير الأسنان؟ ",


        ],

        paragraphs: [
            `إذا كنت تسأل عن السبب الرئيسي في ابتسامة المشاهير؟ وكيف يكون شكلها متساوٍ؟ وكيف تظهر بكل هذا الجمال؟ ان كنت تبحث عن إجابة لأحد هذه الأسئلة فتابع معنا قراءة هذه المقالة ستجد أجابات شافية لهذه الأسئلة وغيرها من الأسئلة الاخرى التي تخص هذا الموضوع وذلك مع الدكتور عمار أخصائي طب و تجميل الاسنان.`,

            `هو عبارة عن تقنية طبية تعتمد على تركيب قشرة تجميلية فوق الأسنان لتعطي لها اللون الأبيض الجميل، نعم حقًا يمكن أن نطلق عليها تقنية عدسات الأسنان اللاصقة، وتصنع هذه القشور من خلال مواد خزفية صلبة (البورسلان) وتكون شديدة البياض، و يتم تصنيع هذه القشور التجميلية في معامل متخصصة بمقاسات معينة، وتختلف هذه المقاسات من شخص إلى آخر، وعلى حسب كل حالة.`,

            `يتم أستخدام الفينير للعديد من الأسباب ومن أهم هذه الأسباب ما يلي:`,
            `

            تقوم بمعالجة تصبغات الأسنان، التي يتم تغير لونها.
            تقوم بمعالجة الشروخ والكسور التي توجد في الأسنان.
            تقوم بإضافة شكل جميل ومميز للأسنان، و يعمل على تعديل شكل الأسنان.
            تقوم بمعالجة حالات الأسنان التي تكون متباعدة عن بعضها.
            `,
            `يوجد العديد من الخطوات التي يتم بها تركيب الفينير ومن أهم هذه الخطوات هي :`,
            `

            يقوم الطبيب بإزالة جزء صغير من مينا الأسنان تكون بنفس حجم الفينير المطلوب كي تعطي شكل مماثل الأسنان الطبيعية.
            ثم يقوم الطبيب بإرسال نموذج من شكل الأسنان، لكي يقوم المختبر بصناعتها خلال أسبوعين.
            ثم يعمل الطبيب على تنظيف الأسنان لكي تصبح أكثر خشونة؛ ويساعد ذلك الإجراء في تثبيت الفينير بشكل أكبر.
            في النهاية يقوم الطبيب بإستخدام أشعة ضوئية تكون مخصصة لإلصاق الفينير على السن الطبيعي.
            `,

            `يستطيع جميع الأشخاص والفئات أن يخضعوا لعمل الفينير للأسنان ، ويمكن أن نوضح أن الفينير هو إجراء تجميلي وليس علاجي؛ لذلك يجب علي الشخص الذي سيخضع لإجراء الفينير

            يجب أن تكون أسنان المريض سليمة وخالية من أي تسوس.
            يجب أن تكون اللثة خالية من أي التهاب.
            يجب أن تكون أسنانه في صحة جيدة.
            `,

            `أهم ما يميزنا في عيادات دكتور عمار أننا نمتلك فريق طبي متكامل لعلاج كافة المشكلات التي تواجه المريض، بالإضافة أننا نمتلك أحدث التقنيات والاجهزة لإتمام كل الإجراءات بسهولة ودون ألم مع تحقيق أفضل النتائج.
            `,
            `يمكنك التواصل معنا للأستفسار أكثر عن خدمة الفينير، وتواصلك معنا يعد هو الخطوة الأولى للاستمتاع بابتسامة أجمل وسيتم الرد عليكم في أسرع وقت ممكن.

            `
        ]


    }



    ,


    {
        category: "زراعه",
        images: [
            "https://drammar-clinics.com/images/products/imp.jpg",
            "https://drammar-clinics.com/images/products/products-img-8.jpg",
            "https://drammar-clinics.com/images/products/products-img-7.jpg"
        ],
        headerss: [
            `زراعة الاسنان
          `,

            `فوائد زراعة الأسنان؟
       `,
            `الشروط اللازمة لزراعة الأسنان
`,

            `كيفية زراعة الاسنان؟
       `,
            `أسعار زراعة الاسنان في مصر
`,
           
          


        ],

        paragraphs: [
            `تعتبر زراعة الأسنان من أكثر الخدمات شيوعًا في طب الأسنان، فهي الحل الأمثل لتعويض الأسنان المفقودة والحصول على أسنان دائمة دون التأثير على الأسنان المحيطة وذلك؛ من خلال استبدال الأسنان المفقودة بأسنان صناعية تشبه الأسنان الطبيعية.

            `,

            `تتميز زراعة الاسنان بالعديد من المزايا ومن أبرزها:`,
            `

            استعادة القدرة على المضغ.
            الحصول على المظهر التجميلي.
            الحفاظ على عظام الفك من الانكماش نتيجة فقدان العظام.
            الحفاظ على صحة العظام واللثة.
            علاج مشاكل النطق.
            `,

            `هناك بعض الشروط التي يجب توافرها لدى المريض قبل إجراء عملية زراعة الأسنان، ومن هذه الشروط:`,
            `

            اكتمال نمو عظام الفك لدى المريض .
            قابلية عظام الفك إلى الزراعة.
            عدم وجود أي مشاكل في أنسجة الفم تعوق زراعة الاسنان.
            عدم وجود أي مشكلات صحية تمنع القيام بعملية زراعة الأسنان.
            أن يكون المريض من غير المدخنين.
            
            `,
            `تمر زراعة الاسنان بعدة مراحل:
            المرحلة الاولي: يقوم الطبيب بزرع العظم إذا كان عظم المريض لا يسمح بإجراء الزراعة، وعادةً ما تستغرق هذه المرحلة ٣ أشهر حتى يتم الالتحام العظمي.
            المرحله الثانيه: في المرحلة الثانية من زراعة الأسنان يقوم الطبيب بتركيب الأسنان الاصطناعية بشكل يتناسب مع الأسنان المحيطة.
            
            
            `,



            `لا يوجد سعر محدد لزراعة الاسنان؛ لـذلك يتم تحديد سعر عملية زراعة الأسنان بناءًاعلي بعض العوامل منها:`,
            `
كفاءة الطبيب و درجته العلمية.
خبرة المركز أو العيادة التي يتم فيها إجراء العملية.
جودة التقنيات الطبية المستخدمة.
التحاليل التي يخضع لها المريض قبل إجراء العملية.
الرعاية الصحية التي يحصل عليها المريض.

            `,




            `تختلف مدة زراعة الأسنان من شخص لآخر فهي تعتمد على حالة عظم الفك لدى المريض، ولكنها عادةً ما تستغرق مدة تتراوح من 3 الي 6 اشهر.

  `,
           
        ]


    }

    ,



    {
        category: "تقويم",
        images: [
            "https://drammar-clinics.com/images/products/Orth.jpg",
            "https://drammar-clinics.com/images/products/products-img-8.jpg",
            "https://drammar-clinics.com/images/products/products-img-7.jpg"
        ],
        headerss: [
            `تقويم الاسنان
          `,

            `أهمية تقويم الأسنان `,
            ` انواع تقويم الاسنان`,

            ` التقويم الثابت `,
            `التقويم المتحرك`,
          

        ],

        paragraphs: [
            `تقويم الأسنان هو فرع من فروع طب الأسنان الذي يعالج العديد من مشكلات الأسنان سواء كانت هذه المشكلات وظيفية أو جمالية، مما يجعل الأسنان تبدو بمظهر طبيعي رائع وتؤدي وظيفتها على أكمل وجه دون الشعور بالألم. لنتعرف أكثر عن تقويم الأسنان وأهميته وأنواعه مع دكتور عمار أخصائي طب و تجميل الاسنان.
            `,

            `قد يعتقد البعض أن تقويم الأسنان له فوائد جمالية فقط، ولكن لتقويم الأسنان فوائد اخرى تزيد عن تحسين المظهر التجميلي للأسنان؛ فهو يعالج العديد من مشكلات الأسنان، ومن أبرز المشكلات التي يمكن لتقويم الاسنان علاجها، والتخلص منها نهائيا :
`,
            ` علاج مشاكل النطق والتلعثم في الكلام.
           علاج الأسنان المغروزة في الفك.
           تحسين عملية المضغ.
           تزاحم الأسنان.
           بروز الفكين.
           العضة المفتوح.
           تعزيز صحة اللثة والأسنان على المدى الطويل.
           تقليل فرصة تلف الأسنان البارزة.
           
            `,

            `يعتبر تقويم الأسنان الثابت من أكثر أنواع التقويم شيوعًا نظرًا لنتائجه الموثوقة، وتختلف مدة العلاج باستخدام التقويم الثابت من حالة لأخرى؛ ولكنها تتراوح عادةً ما بين 18 إلى 24 شهرًا، وهناك أنواع مختلفة من التقويم الثابت مثل المعدني او الشفاف.
            `,
            `يستخدم هذا النوع من تقويم الأسنان في الحالات البسيطة، وعادة ما تتراوح مدة العلاج باستخدام التقويم المتحرك بين 12 إلى 18 شهرًا، وأيضًا تختلف هذه المدة بناءا على رؤية الطبيب.


            
            `,



            `ينصح الأطباء بمعالجة مشكلات الأسنان لدى الأطفال و تركيب تقويم الاسنان في سن مبكر حتى لا يعاني منها الطفل فيما بعد.

            `,




            `ويعد السن المناسب للذهاب إلى الطبيب هو قبل أن يفقد الطفل آخر مجموعة من الأسنان، ويختلف ذلك من طفل لآخر، لكنه عادة ما يبدأ من سن 6 إلى 12 عام ويتم معالجة هذه المشاكل بسهولة في هذا العمر.


  `,
            `يعتبر دكتور عمان اكبر وافضل الاطباء لخدمة تقويم الاسنان في مصر؛ وذلك لأنه يتميز بالعديد من المزايا التي تجعله الاختيار الأفضل ومن هذه المزايا اننا نعتمد على فريق طبي مميز، ويتوفر لدينا أحدث الأجهزة والتقنيات المستخدمة في تقويم الاسنان عالميًا.

            `,
            `
            للحجز مع دكتور عمار أخصائي طب و تجميل الاسنان؛ يمكنك التواصل معنا عبر خدمة الرسائل النصية الموجودة في الموقع الالكتروني؛ وسيتم التواصل معك مباشرةً من قبل الفريق الطبي.



            `
        ]


    }


    ,

  



    {
        category: "حشو",
        images: [
            "https://drammar-clinics.com/images/products/Com.JPG",
            "https://drammar-clinics.com/images/products/Com3.JPG",
            "https://drammar-clinics.com/images/products/products-img-7.jpg"
        ],
        headerss: [
            `الحشو التجميلي
 

          `,

            `   ما هي الحشوات التجميلية؟
 
            `,
            ` أنواع الحشوات التجميلية

            `,

            ` ما هي خطوات تركيب الحشوات التجميلية؟
            `,
            `أهميته الحشو التجميلي
`

        ],

        paragraphs: [
            `تقويم الأسنان هو فرع من فروع طب الأسنان الذي يعالج العديد من مشكلات الأسنان سواء كانت هذه المشكلات وظيفية أو جمالية، مما يجعل الأسنان تبدو بمظهر طبيعي رائع وتؤدي وظيفتها على أكمل وجه دون الشعور بالألم. لنتعرف أكثر عن تقويم الأسنان وأهميته وأنواعه مع دكتور عمار أخصائي طب و تجميل الاسنان.
            `,

            `قد يعتقد البعض أن تقويم الأسنان له فوائد جمالية فقط، ولكن لتقويم الأسنان فوائد اخرى تزيد عن تحسين المظهر التجميلي للأسنان؛ فهو يعالج العديد من مشكلات الأسنان، ومن أبرز المشكلات التي يمكن لتقويم الاسنان علاجها، والتخلص منها نهائيا :
`,
            ` علاج مشاكل النطق والتلعثم في الكلام.
           علاج الأسنان المغروزة في الفك.
           تحسين عملية المضغ.
           تزاحم الأسنان.
           بروز الفكين.
           العضة المفتوح.
           تعزيز صحة اللثة والأسنان على المدى الطويل.
           تقليل فرصة تلف الأسنان البارزة.
           
            `,

            `يعتبر تقويم الأسنان الثابت من أكثر أنواع التقويم شيوعًا نظرًا لنتائجه الموثوقة، وتختلف مدة العلاج باستخدام التقويم الثابت من حالة لأخرى؛ ولكنها تتراوح عادةً ما بين 18 إلى 24 شهرًا، وهناك أنواع مختلفة من التقويم الثابت مثل المعدني او الشفاف.
            `,
            `يستخدم هذا النوع من تقويم الأسنان في الحالات البسيطة، وعادة ما تتراوح مدة العلاج باستخدام التقويم المتحرك بين 12 إلى 18 شهرًا، وأيضًا تختلف هذه المدة بناءا على رؤية الطبيب.


            
            `,



            `ينصح الأطباء بمعالجة مشكلات الأسنان لدى الأطفال و تركيب تقويم الاسنان في سن مبكر حتى لا يعاني منها الطفل فيما بعد.

            `,




            `ويعد السن المناسب للذهاب إلى الطبيب هو قبل أن يفقد الطفل آخر مجموعة من الأسنان، ويختلف ذلك من طفل لآخر، لكنه عادة ما يبدأ من سن 6 إلى 12 عام ويتم معالجة هذه المشاكل بسهولة في هذا العمر.


  `,
            `يعتبر دكتور عمان اكبر وافضل الاطباء لخدمة تقويم الاسنان في مصر؛ وذلك لأنه يتميز بالعديد من المزايا التي تجعله الاختيار الأفضل ومن هذه المزايا اننا نعتمد على فريق طبي مميز، ويتوفر لدينا أحدث الأجهزة والتقنيات المستخدمة في تقويم الاسنان عالميًا.

            `,
            `
            للحجز مع دكتور عمار أخصائي طب و تجميل الاسنان؛ يمكنك التواصل معنا عبر خدمة الرسائل النصية الموجودة في الموقع الالكتروني؛ وسيتم التواصل معك مباشرةً من قبل الفريق الطبي.



            `
        ]


    }
,

{
    category: "تنظيف",
    images: [
        "https://drammar-clinics.com/images/products/per.jpg",
        "https://drammar-clinics.com/images/products/Com3.JPG",
        "https://drammar-clinics.com/images/products/products-img-7.jpg"
    ],
    headerss: [
        ` تنظيف الجير



      `,

        `   طرق تنظيف الاسنان من الجير


        `,
        ` ما الأضرار الناتجة عن عدم تنظيف الاسنان من الجير؟


        `,

        ` هل تنظيف الجير من الأسنان مؤلم؟

        `,
        `ما سعر تنظيف الاسنان من الجير؟

`

    ],

    paragraphs: [
        `تقويم الأسنان هو فرع من فروع طب الأسنان الذي يعالج العديد من مشكلات الأسنان سواء كانت هذه المشكلات وظيفية أو جمالية، مما يجعل الأسنان تبدو بمظهر طبيعي رائع وتؤدي وظيفتها على أكمل وجه دون الشعور بالألم. لنتعرف أكثر عن تقويم الأسنان وأهميته وأنواعه مع دكتور عمار أخصائي طب و تجميل الاسنان.
        `,

        `قد يعتقد البعض أن تقويم الأسنان له فوائد جمالية فقط، ولكن لتقويم الأسنان فوائد اخرى تزيد عن تحسين المظهر التجميلي للأسنان؛ فهو يعالج العديد من مشكلات الأسنان، ومن أبرز المشكلات التي يمكن لتقويم الاسنان علاجها، والتخلص منها نهائيا :
`,
        ` علاج مشاكل النطق والتلعثم في الكلام.
       علاج الأسنان المغروزة في الفك.
       تحسين عملية المضغ.
       تزاحم الأسنان.
       بروز الفكين.
       العضة المفتوح.
       تعزيز صحة اللثة والأسنان على المدى الطويل.
       تقليل فرصة تلف الأسنان البارزة.
       
        `,

        `يعتبر تقويم الأسنان الثابت من أكثر أنواع التقويم شيوعًا نظرًا لنتائجه الموثوقة، وتختلف مدة العلاج باستخدام التقويم الثابت من حالة لأخرى؛ ولكنها تتراوح عادةً ما بين 18 إلى 24 شهرًا، وهناك أنواع مختلفة من التقويم الثابت مثل المعدني او الشفاف.
        `,
        `يستخدم هذا النوع من تقويم الأسنان في الحالات البسيطة، وعادة ما تتراوح مدة العلاج باستخدام التقويم المتحرك بين 12 إلى 18 شهرًا، وأيضًا تختلف هذه المدة بناءا على رؤية الطبيب.


        
        `,



        `ينصح الأطباء بمعالجة مشكلات الأسنان لدى الأطفال و تركيب تقويم الاسنان في سن مبكر حتى لا يعاني منها الطفل فيما بعد.

        `,




        `ويعد السن المناسب للذهاب إلى الطبيب هو قبل أن يفقد الطفل آخر مجموعة من الأسنان، ويختلف ذلك من طفل لآخر، لكنه عادة ما يبدأ من سن 6 إلى 12 عام ويتم معالجة هذه المشاكل بسهولة في هذا العمر.


`,
        `يعتبر دكتور عمان اكبر وافضل الاطباء لخدمة تقويم الاسنان في مصر؛ وذلك لأنه يتميز بالعديد من المزايا التي تجعله الاختيار الأفضل ومن هذه المزايا اننا نعتمد على فريق طبي مميز، ويتوفر لدينا أحدث الأجهزة والتقنيات المستخدمة في تقويم الاسنان عالميًا.

        `,
        `
        للحجز مع دكتور عمار أخصائي طب و تجميل الاسنان؛ يمكنك التواصل معنا عبر خدمة الرسائل النصية الموجودة في الموقع الالكتروني؛ وسيتم التواصل معك مباشرةً من قبل الفريق الطبي.



        `
    ]


}

,

{
    category: "تركيبات",
    images: [
        "https://drammar-clinics.com/images/products/products-img-10.jpg",
        "https://drammar-clinics.com/images/products/Com3.JPG",
        "https://drammar-clinics.com/images/products/products-img-7.jpg"
    ],
    headerss: [
        ` التركيبات
      `,

        ` ما هي عملية تركيب الأسنان؟


        `,
        ` ما هي أنواع تركيبات الأسنان ؟

        `,

        ` ما فوائد تركيب الأسنان ؟

        `,
        `ما سعر تركيب الاسنان من الجير؟

`

    ],

    paragraphs: [
        `تقويم الأسنان هو فرع من فروع طب الأسنان الذي يعالج العديد من مشكلات الأسنان سواء كانت هذه المشكلات وظيفية أو جمالية، مما يجعل الأسنان تبدو بمظهر طبيعي رائع وتؤدي وظيفتها على أكمل وجه دون الشعور بالألم. لنتعرف أكثر عن تقويم الأسنان وأهميته وأنواعه مع دكتور عمار أخصائي طب و تجميل الاسنان.
        `,

        `قد يعتقد البعض أن تقويم الأسنان له فوائد جمالية فقط، ولكن لتقويم الأسنان فوائد اخرى تزيد عن تحسين المظهر التجميلي للأسنان؛ فهو يعالج العديد من مشكلات الأسنان، ومن أبرز المشكلات التي يمكن لتقويم الاسنان علاجها، والتخلص منها نهائيا :
`,
        ` علاج مشاكل النطق والتلعثم في الكلام.
       علاج الأسنان المغروزة في الفك.
       تحسين عملية المضغ.
       تزاحم الأسنان.
       بروز الفكين.
       العضة المفتوح.
       تعزيز صحة اللثة والأسنان على المدى الطويل.
       تقليل فرصة تلف الأسنان البارزة.
       
        `,

        `يعتبر تقويم الأسنان الثابت من أكثر أنواع التقويم شيوعًا نظرًا لنتائجه الموثوقة، وتختلف مدة العلاج باستخدام التقويم الثابت من حالة لأخرى؛ ولكنها تتراوح عادةً ما بين 18 إلى 24 شهرًا، وهناك أنواع مختلفة من التقويم الثابت مثل المعدني او الشفاف.
        `,
        `يستخدم هذا النوع من تقويم الأسنان في الحالات البسيطة، وعادة ما تتراوح مدة العلاج باستخدام التقويم المتحرك بين 12 إلى 18 شهرًا، وأيضًا تختلف هذه المدة بناءا على رؤية الطبيب.


        
        `,



        `ينصح الأطباء بمعالجة مشكلات الأسنان لدى الأطفال و تركيب تقويم الاسنان في سن مبكر حتى لا يعاني منها الطفل فيما بعد.

        `,




        `ويعد السن المناسب للذهاب إلى الطبيب هو قبل أن يفقد الطفل آخر مجموعة من الأسنان، ويختلف ذلك من طفل لآخر، لكنه عادة ما يبدأ من سن 6 إلى 12 عام ويتم معالجة هذه المشاكل بسهولة في هذا العمر.


`,
        `يعتبر دكتور عمان اكبر وافضل الاطباء لخدمة تقويم الاسنان في مصر؛ وذلك لأنه يتميز بالعديد من المزايا التي تجعله الاختيار الأفضل ومن هذه المزايا اننا نعتمد على فريق طبي مميز، ويتوفر لدينا أحدث الأجهزة والتقنيات المستخدمة في تقويم الاسنان عالميًا.

        `,
        `
        للحجز مع دكتور عمار أخصائي طب و تجميل الاسنان؛ يمكنك التواصل معنا عبر خدمة الرسائل النصية الموجودة في الموقع الالكتروني؛ وسيتم التواصل معك مباشرةً من قبل الفريق الطبي.



        `
    ]


}
,
{
    category: "خلع",
    images: [
        "https://drammar-clinics.com/images/products/crown.jpg",
        "https://drammar-clinics.com/images/products/Com3.JPG",
        "https://drammar-clinics.com/images/products/products-img-7.jpg"
    ],
    headerss: [
        ` الخلع والخلع الجراحي

      `,

        ` ما هي أنواع خلع الأسنان؟


        `,
        `ثانيًا الخلع الجراحي للأسنان

        `,

        `متى نلجأ إلى الخلع الجراحي؟

        `,
        `كيف يتم الخلع الجراحي؟

`

    ],

    paragraphs: [
        `تقويم الأسنان هو فرع من فروع طب الأسنان الذي يعالج العديد من مشكلات الأسنان سواء كانت هذه المشكلات وظيفية أو جمالية، مما يجعل الأسنان تبدو بمظهر طبيعي رائع وتؤدي وظيفتها على أكمل وجه دون الشعور بالألم. لنتعرف أكثر عن تقويم الأسنان وأهميته وأنواعه مع دكتور عمار أخصائي طب و تجميل الاسنان.
        `,

        `قد يعتقد البعض أن تقويم الأسنان له فوائد جمالية فقط، ولكن لتقويم الأسنان فوائد اخرى تزيد عن تحسين المظهر التجميلي للأسنان؛ فهو يعالج العديد من مشكلات الأسنان، ومن أبرز المشكلات التي يمكن لتقويم الاسنان علاجها، والتخلص منها نهائيا :
`,
        ` علاج مشاكل النطق والتلعثم في الكلام.
       علاج الأسنان المغروزة في الفك.
       تحسين عملية المضغ.
       تزاحم الأسنان.
       بروز الفكين.
       العضة المفتوح.
       تعزيز صحة اللثة والأسنان على المدى الطويل.
       تقليل فرصة تلف الأسنان البارزة.
       
        `,

        `يعتبر تقويم الأسنان الثابت من أكثر أنواع التقويم شيوعًا نظرًا لنتائجه الموثوقة، وتختلف مدة العلاج باستخدام التقويم الثابت من حالة لأخرى؛ ولكنها تتراوح عادةً ما بين 18 إلى 24 شهرًا، وهناك أنواع مختلفة من التقويم الثابت مثل المعدني او الشفاف.
        `,
        `يستخدم هذا النوع من تقويم الأسنان في الحالات البسيطة، وعادة ما تتراوح مدة العلاج باستخدام التقويم المتحرك بين 12 إلى 18 شهرًا، وأيضًا تختلف هذه المدة بناءا على رؤية الطبيب.


        
        `,



        `ينصح الأطباء بمعالجة مشكلات الأسنان لدى الأطفال و تركيب تقويم الاسنان في سن مبكر حتى لا يعاني منها الطفل فيما بعد.

        `,




        `ويعد السن المناسب للذهاب إلى الطبيب هو قبل أن يفقد الطفل آخر مجموعة من الأسنان، ويختلف ذلك من طفل لآخر، لكنه عادة ما يبدأ من سن 6 إلى 12 عام ويتم معالجة هذه المشاكل بسهولة في هذا العمر.


`,
        `يعتبر دكتور عمان اكبر وافضل الاطباء لخدمة تقويم الاسنان في مصر؛ وذلك لأنه يتميز بالعديد من المزايا التي تجعله الاختيار الأفضل ومن هذه المزايا اننا نعتمد على فريق طبي مميز، ويتوفر لدينا أحدث الأجهزة والتقنيات المستخدمة في تقويم الاسنان عالميًا.

        `,
        `
        للحجز مع دكتور عمار أخصائي طب و تجميل الاسنان؛ يمكنك التواصل معنا عبر خدمة الرسائل النصية الموجودة في الموقع الالكتروني؛ وسيتم التواصل معك مباشرةً من قبل الفريق الطبي.



        `
    ]


}

,
{
    category: "الاطفال",
    images: [
        "https://drammar-clinics.com/images/products/products-img-10.jpg",
        "https://drammar-clinics.com/images/products/Com3.JPG",
        "https://drammar-clinics.com/images/products/products-img-7.jpg"
    ],
    headerss: [
        ` 
        حشو الاطفال

      `,

        ` 
        خطوات حشو الأسنان للأطفال


        `,
        `
        أنواع حشو الأسنان

        `,

        `
        متى يلجأ الشخص إلى حشو الأسنان ؟

        `,
        `
        كم عدد جلسات الحشو للأطفال؟

`

    ],

    paragraphs: [
        `تقويم الأسنان هو فرع من فروع طب الأسنان الذي يعالج العديد من مشكلات الأسنان سواء كانت هذه المشكلات وظيفية أو جمالية، مما يجعل الأسنان تبدو بمظهر طبيعي رائع وتؤدي وظيفتها على أكمل وجه دون الشعور بالألم. لنتعرف أكثر عن تقويم الأسنان وأهميته وأنواعه مع دكتور عمار أخصائي طب و تجميل الاسنان.
        `,

        `قد يعتقد البعض أن تقويم الأسنان له فوائد جمالية فقط، ولكن لتقويم الأسنان فوائد اخرى تزيد عن تحسين المظهر التجميلي للأسنان؛ فهو يعالج العديد من مشكلات الأسنان، ومن أبرز المشكلات التي يمكن لتقويم الاسنان علاجها، والتخلص منها نهائيا :
`,
        ` علاج مشاكل النطق والتلعثم في الكلام.
       علاج الأسنان المغروزة في الفك.
       تحسين عملية المضغ.
       تزاحم الأسنان.
       بروز الفكين.
       العضة المفتوح.
       تعزيز صحة اللثة والأسنان على المدى الطويل.
       تقليل فرصة تلف الأسنان البارزة.
       
        `,

        `يعتبر تقويم الأسنان الثابت من أكثر أنواع التقويم شيوعًا نظرًا لنتائجه الموثوقة، وتختلف مدة العلاج باستخدام التقويم الثابت من حالة لأخرى؛ ولكنها تتراوح عادةً ما بين 18 إلى 24 شهرًا، وهناك أنواع مختلفة من التقويم الثابت مثل المعدني او الشفاف.
        `,
        `يستخدم هذا النوع من تقويم الأسنان في الحالات البسيطة، وعادة ما تتراوح مدة العلاج باستخدام التقويم المتحرك بين 12 إلى 18 شهرًا، وأيضًا تختلف هذه المدة بناءا على رؤية الطبيب.


        
        `,



        `ينصح الأطباء بمعالجة مشكلات الأسنان لدى الأطفال و تركيب تقويم الاسنان في سن مبكر حتى لا يعاني منها الطفل فيما بعد.

        `,




        `ويعد السن المناسب للذهاب إلى الطبيب هو قبل أن يفقد الطفل آخر مجموعة من الأسنان، ويختلف ذلك من طفل لآخر، لكنه عادة ما يبدأ من سن 6 إلى 12 عام ويتم معالجة هذه المشاكل بسهولة في هذا العمر.


`,
        `يعتبر دكتور عمان اكبر وافضل الاطباء لخدمة تقويم الاسنان في مصر؛ وذلك لأنه يتميز بالعديد من المزايا التي تجعله الاختيار الأفضل ومن هذه المزايا اننا نعتمد على فريق طبي مميز، ويتوفر لدينا أحدث الأجهزة والتقنيات المستخدمة في تقويم الاسنان عالميًا.

        `,
        `
        للحجز مع دكتور عمار أخصائي طب و تجميل الاسنان؛ يمكنك التواصل معنا عبر خدمة الرسائل النصية الموجودة في الموقع الالكتروني؛ وسيتم التواصل معك مباشرةً من قبل الفريق الطبي.



        `
    ]


}
,

{
    category: "العصب",
    images: [
        "https://drammar-clinics.com/images/products/products-img-11.jpg",
        "https://drammar-clinics.com/images/products/Com3.JPG",
        "https://drammar-clinics.com/images/products/products-img-7.jpg"
    ],
    headerss: [
        ` 
        حشو العصب

      `,

        ` 
        ما هو حشو العصب؟


        `,
        `
        ما هي الأسباب التي تؤدي إلى حشو العصب ؟

        `,

        `
        ما هي خطوات حشو العصب؟

        `,
        `
        هل حشو العصب مؤلم ؟

`

    ],

    paragraphs: [
        `تقويم الأسنان هو فرع من فروع طب الأسنان الذي يعالج العديد من مشكلات الأسنان سواء كانت هذه المشكلات وظيفية أو جمالية، مما يجعل الأسنان تبدو بمظهر طبيعي رائع وتؤدي وظيفتها على أكمل وجه دون الشعور بالألم. لنتعرف أكثر عن تقويم الأسنان وأهميته وأنواعه مع دكتور عمار أخصائي طب و تجميل الاسنان.
        `,

        `قد يعتقد البعض أن تقويم الأسنان له فوائد جمالية فقط، ولكن لتقويم الأسنان فوائد اخرى تزيد عن تحسين المظهر التجميلي للأسنان؛ فهو يعالج العديد من مشكلات الأسنان، ومن أبرز المشكلات التي يمكن لتقويم الاسنان علاجها، والتخلص منها نهائيا :
`,
        ` علاج مشاكل النطق والتلعثم في الكلام.
       علاج الأسنان المغروزة في الفك.
       تحسين عملية المضغ.
       تزاحم الأسنان.
       بروز الفكين.
       العضة المفتوح.
       تعزيز صحة اللثة والأسنان على المدى الطويل.
       تقليل فرصة تلف الأسنان البارزة.
       
        `,

        `يعتبر تقويم الأسنان الثابت من أكثر أنواع التقويم شيوعًا نظرًا لنتائجه الموثوقة، وتختلف مدة العلاج باستخدام التقويم الثابت من حالة لأخرى؛ ولكنها تتراوح عادةً ما بين 18 إلى 24 شهرًا، وهناك أنواع مختلفة من التقويم الثابت مثل المعدني او الشفاف.
        `,
        `يستخدم هذا النوع من تقويم الأسنان في الحالات البسيطة، وعادة ما تتراوح مدة العلاج باستخدام التقويم المتحرك بين 12 إلى 18 شهرًا، وأيضًا تختلف هذه المدة بناءا على رؤية الطبيب.


        
        `,



        `ينصح الأطباء بمعالجة مشكلات الأسنان لدى الأطفال و تركيب تقويم الاسنان في سن مبكر حتى لا يعاني منها الطفل فيما بعد.

        `,




        `ويعد السن المناسب للذهاب إلى الطبيب هو قبل أن يفقد الطفل آخر مجموعة من الأسنان، ويختلف ذلك من طفل لآخر، لكنه عادة ما يبدأ من سن 6 إلى 12 عام ويتم معالجة هذه المشاكل بسهولة في هذا العمر.


`,
        `يعتبر دكتور عمان اكبر وافضل الاطباء لخدمة تقويم الاسنان في مصر؛ وذلك لأنه يتميز بالعديد من المزايا التي تجعله الاختيار الأفضل ومن هذه المزايا اننا نعتمد على فريق طبي مميز، ويتوفر لدينا أحدث الأجهزة والتقنيات المستخدمة في تقويم الاسنان عالميًا.

        `,
        `
        للحجز مع دكتور عمار أخصائي طب و تجميل الاسنان؛ يمكنك التواصل معنا عبر خدمة الرسائل النصية الموجودة في الموقع الالكتروني؛ وسيتم التواصل معك مباشرةً من قبل الفريق الطبي.



        `
    ]


}

,

{
    category: "تبيض",
    images: [
        "https://drammar-clinics.com/images/products/white.jpg",
        "https://drammar-clinics.com/images/products/Com3.JPG",
        "https://drammar-clinics.com/images/products/products-img-7.jpg"
    ],
    headerss: [
        ` 
        تبييض الليزر

      `,

        ` 
        مميزات تبييض الليزر


        `,
        `
        أهم النصائح بعد تبييض الاسنان بالليزر

        `,

        `
        أسعار تبييض الاسنان بالليزر؟

        `,
        `
        مركز د عمار - الافضل في التبييض بالليزر
`

    ],

    paragraphs: [
        `تقويم الأسنان هو فرع من فروع طب الأسنان الذي يعالج العديد من مشكلات الأسنان سواء كانت هذه المشكلات وظيفية أو جمالية، مما يجعل الأسنان تبدو بمظهر طبيعي رائع وتؤدي وظيفتها على أكمل وجه دون الشعور بالألم. لنتعرف أكثر عن تقويم الأسنان وأهميته وأنواعه مع دكتور عمار أخصائي طب و تجميل الاسنان.
        `,

        `قد يعتقد البعض أن تقويم الأسنان له فوائد جمالية فقط، ولكن لتقويم الأسنان فوائد اخرى تزيد عن تحسين المظهر التجميلي للأسنان؛ فهو يعالج العديد من مشكلات الأسنان، ومن أبرز المشكلات التي يمكن لتقويم الاسنان علاجها، والتخلص منها نهائيا :
`,
        ` علاج مشاكل النطق والتلعثم في الكلام.
       علاج الأسنان المغروزة في الفك.
       تحسين عملية المضغ.
       تزاحم الأسنان.
       بروز الفكين.
       العضة المفتوح.
       تعزيز صحة اللثة والأسنان على المدى الطويل.
       تقليل فرصة تلف الأسنان البارزة.
       
        `,

        `يعتبر تقويم الأسنان الثابت من أكثر أنواع التقويم شيوعًا نظرًا لنتائجه الموثوقة، وتختلف مدة العلاج باستخدام التقويم الثابت من حالة لأخرى؛ ولكنها تتراوح عادةً ما بين 18 إلى 24 شهرًا، وهناك أنواع مختلفة من التقويم الثابت مثل المعدني او الشفاف.
        `,
        `يستخدم هذا النوع من تقويم الأسنان في الحالات البسيطة، وعادة ما تتراوح مدة العلاج باستخدام التقويم المتحرك بين 12 إلى 18 شهرًا، وأيضًا تختلف هذه المدة بناءا على رؤية الطبيب.


        
        `,



        `ينصح الأطباء بمعالجة مشكلات الأسنان لدى الأطفال و تركيب تقويم الاسنان في سن مبكر حتى لا يعاني منها الطفل فيما بعد.

        `,




        `ويعد السن المناسب للذهاب إلى الطبيب هو قبل أن يفقد الطفل آخر مجموعة من الأسنان، ويختلف ذلك من طفل لآخر، لكنه عادة ما يبدأ من سن 6 إلى 12 عام ويتم معالجة هذه المشاكل بسهولة في هذا العمر.


`,
        `يعتبر دكتور عمان اكبر وافضل الاطباء لخدمة تقويم الاسنان في مصر؛ وذلك لأنه يتميز بالعديد من المزايا التي تجعله الاختيار الأفضل ومن هذه المزايا اننا نعتمد على فريق طبي مميز، ويتوفر لدينا أحدث الأجهزة والتقنيات المستخدمة في تقويم الاسنان عالميًا.

        `,
        `
        للحجز مع دكتور عمار أخصائي طب و تجميل الاسنان؛ يمكنك التواصل معنا عبر خدمة الرسائل النصية الموجودة في الموقع الالكتروني؛ وسيتم التواصل معك مباشرةً من قبل الفريق الطبي.



        `
    ]


}



]


export default Apis;




